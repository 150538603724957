import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { JwtService } from "../shared/jwt.service";
import { environment } from "../../environments/environment";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CustomerSessionService {

  public clientName: string = "";
  public tppName: string = "";

  constructor(private http: HttpClient, private jwtService: JwtService) { }

  public getSessionsFromServer() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    const url = `${environment.supportingAPIUrl}/customer-auth/sessions`;
    
    return this.http.get(url, { headers });
  }

}
