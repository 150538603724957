import {Component, HostListener, NgZone, OnInit} from '@angular/core';
import {InitializerService} from './initializer.service';
import {Router} from '@angular/router';
import {JwtService} from './shared/jwt.service';
import {ObieConsentsService} from './flows/OBIE/shared/obie-consents.service';
import {BerlinConsentsService} from './flows/berlin/shared/berlin-consents.service';
import {CanonicalConsent, CanonicalDomesticRequest} from './shared/interfaces';
import {AppTimeServices} from './shared/appTime.service';
import {BrowserFavicons} from './shared/favicon/favicons';
import {ModalService} from './flows/OBIE/shared/common/modal/modal.service';
import {Modal} from './shared/enums';
import { CustomerSessionService } from './shared/customer-session.service';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  brand: string;
  showLoader: boolean;
  title = 'customer-auth-ui';
  consentService;


  constructor(private initializer: InitializerService,
              private jwtService: JwtService,
              private router: Router,
              private obieConsentService: ObieConsentsService,
              private berlinConsentService: BerlinConsentsService,
              private appTime: AppTimeServices,
              private ngZone: NgZone,
              private favIcons: BrowserFavicons,
              private modalService: ModalService,
              private customerSessionService: CustomerSessionService) {
  }

  setTimeWarnings() {
    this.appTime.showRedirectMessage.subscribe(response => {
      this.ngZone.run(() => {
        if (response) {
          this.modalService.openModal(Modal.REDIRECTION_TO_TPP);
        }
      });
    });

    this.appTime.showLastTimeWarning.subscribe(response => {
      this.ngZone.run(() => {
        if (response) {
          this.modalService.openModal(Modal.LAST_TIMEOUT_WARNING);
        }
      });
    });

    this.appTime.showInactivityModal.subscribe(response => {
      this.ngZone.run(() => {
        if (response) {
          this.modalService.openModal(Modal.TIMEOUT_WARNING);
        }
      });
    });

  }
  browserRedirect(){
   location.href=environment.browserRedirectUrl;
  }
  @HostListener('window:mousemove')
  @HostListener('window:mousedown')
  @HostListener('window:keydown')
  @HostListener('window:scroll')
  @HostListener('window:touchstart')

  resetInactivityOnUserEvent() {
    this.appTime.inactivity = 0;
  }

  ngOnInit() {
    this.browserRedirect();
    if ('NodeList' in window && !NodeList.prototype['forEach']) {
      NodeList.prototype['forEach'] = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this);
        }
      };
    }
    if (this.jwtService.decodedJwt) {
      this.brand = this.jwtService.brand;
      this.favIcons.init(this.brand);
      const standard = this.jwtService.decodedJwt.standard.toLowerCase();
      if (standard === 'obie') {
        this.consentService = this.obieConsentService;
      } else if (standard === 'berlin') {
        this.consentService = this.berlinConsentService;
      }
      this.customerSessionService.getSessionsFromServer().pipe(
        tap((x: any) => { this.customerSessionService.clientName = x.tppInfo.clientName;
        this.customerSessionService.tppName  = x.tppInfo.softwareOn_Behalf_Of_Org; }),
        switchMap(() => this.consentService.getConsentDetails()),
        catchError((err, caught) => {
          console.log("Some error occured");
          return Observable.throw(err);
        })
      ).subscribe(
        (consentDetails: CanonicalConsent & CanonicalDomesticRequest) => {
          this.consentService.consentDetails = consentDetails;
          if (this.initializer.brandName !== 'hsbc' && this.initializer.brandName !== 'firstdirect' && this.initializer.brandName !== 'marksandspencer') {
            this.initializer.brandName = 'hsbc';
          }
          this.router.navigateByUrl(`${this.initializer.brandName}_${this.initializer.flowName}`, {skipLocationChange: true});
          this.setTimeWarnings();
        },
        () => {
          this.router.navigateByUrl('consent_fetch_failure', {skipLocationChange: true});
        }
      );


    } else {
      this.router.navigateByUrl('incorrect_jwt');
    }
  }
}
