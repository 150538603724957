import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

declare var HSBCGLBL: any;
declare function hsbcglblform(): any;

@Injectable()
export class FraudHeaderInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let fraudHeader = this.getFraudHeader();
    const apiReq = req.clone({
      headers: req.headers.set('X-HSBC-Jsc-Data', fraudHeader)
    });
    return next.handle(apiReq);
  }

  private getFraudHeader(): string {
    const hsbcForm = HSBCGLBL.hsbcglblform();
    return hsbcForm;
  }
}
