export enum Modal {
  NONE = 'none',
  SERVICE_UNAVAILABLE = 'serviceUnavailable',
  RB_FORGOTTEN_YOUR_USERNAME = 'rbForgottenYourUsername',
  MNS_FORGOTTEN_YOUR_USERNAME = 'mnsForgottenYourUsername',
  FD_FORGOTTEN_YOUR_USERNAME = 'fdForgottenYourUsername',
  RB_NOT_REGISTERED_FOR_OPEN_BANKING = 'rbNotRegisteredForOpenBanking',
  MNS_NOT_REGISTERED_FOR_OPEN_BANKING = 'mnsNotRegisteredForOpenBanking',
  FD_NOT_REGISTERED_FOR_OPEN_BANKING = 'fdNotRegisteredForOpenBanking',
  RB_LOST_SECURE_KEY = 'rbLostSecureKey',
  FD_LOST_SECURE_KEY = 'fdLostSecureKey',
  MNS_LOST_SECURE_KEY = 'mnsLostSecureKey',
  AISP_WHY_CANT_I_SEE_ALL_MY_ACCOUNTS = 'aispWhyCantISeeAllMyAccounts',
  PISP_WHY_CANT_I_SEE_ALL_MY_ACCOUNTS = 'pispWhyCantISeeAllMyAccounts',
  INFORMATION_SHARING_INCOMPLETE = 'informationSharingIncomplete',
  FUNDS_AVAILABILITY_CHECK_INCOMPLETE = 'fundsAvailabilityCheckIncomplete',
  PAYMENT_INCOMPLETE = 'paymentIncomplete',
  PERMANENT_LOCKOUT = 'permanentLockout',
  INVALID_USERNAME_LAST_ATTEMPT = 'invalidUsernameLastAttempt',
  REDIRECTION_TO_TPP = 'redirectionToTpp',
  LAST_TIMEOUT_WARNING = 'lastTimeoutWarning',
  TIMEOUT_WARNING = 'timeoutWarning',
  INFORMATION_DOES_NOT_MATCH_RECORDS = 'informationDoesNotMatchRecords',
  PAYEE_DETAILS_NOT_VALID = 'payeeDetailsNotValid',
  INSUFFICIENT_REFERENCE = 'insufficientReference',
  AMOUNT_LESS_THAN_FEES = 'amountLessThanFees',
  AMOUNT_LESS_THAN_GBP = 'amountLessThanGBP',
  INSUFFICIENT_FUNDS = 'insufficientFunds',
  INSUFFICIENT_FUNDS_ON_THIS_ACCOUNT = 'insufficientFundsOnThisAccount',
  UNABLE_PAYMENT_ON_SELECTED_DATE = 'unablePaymentOnSelectedDate',
  NO_ELIGIBLE_ACCOUNTS_AISP = 'noEligibleAccountsAisp',
  NO_ELIGIBLE_ACCOUNTS_CBPII = 'noEligibleAccountsCbpii',
  TRANSACTION_COUNT_LIMIT = 'transactionCountLimit',
  TRANSACTION_AMOUNT_LIMIT = 'transactionAmountLimit',
  EXCEEDS_LIMIT_FOR_CURRENCY = 'exceedsLimitForCurrency',
  NO_ELIGIBLE_ACCOUNTS_PISP = 'noEligibleAccountsPisp',
  IN_ELIGIBLE_PAYMENT_ACCOUNT = 'inEligiblePaymentAccount',
  DUPLICATE_PAYMENT = 'duplicatePayment',
  MORTGAGE_ACCOUNT_OVERPAYMENT = 'mortgageAccountOverpayment',
  DUPLICATE_PAYMENT_AND_MORTGAGE_ACCOUNT_OVERPAYMENT = 'duplicatePaymentAndMortgageAccountOverpayment'
}

export enum AccountSubType {
  CURRENT = 'Current Account',
  SAVINGS = 'Savings Account',
  STUDENT = 'Student Account',
  CACC = 'CACC',
  CARD = 'CARD',
  DDA = 'DDA',
  CDA = 'CDA',
  ILA = 'ILA',
  CCA = 'CCA',
  OTH = 'OTH'
}

