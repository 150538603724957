import {Injectable} from '@angular/core';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {CannonicalConsentAISP, CanonicalConsent, CanonicalDomesticRequest} from '../../../shared/interfaces';
import {environment} from '../../../../environments/environment';
import {JwtService} from '../../../shared/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class ObieConsentsService {

  consentDetails: CanonicalConsent & CanonicalDomesticRequest;

  constructor(private http: HttpClient, private jwtService: JwtService) {
  }

  getConsentDetails() {
    const url = environment.supportingAPIUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    return this.http.get(`${url}/customer-auth/data`, {headers});
  }

  putConsent(pickedAccounts?: any, consentDescription?: string) {
    const url = environment.supportingAPIUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    const consent: CannonicalConsentAISP = {};
    if (pickedAccounts) {
      consent['permissions'] = this.consentDetails.permissions.map(permission => {
        return {
          permission: permission.permission,
          accounts: pickedAccounts.map(pickedAccount => {
            return {
              schemeName: pickedAccount.schemeName,
              identification: pickedAccount.fullIdentification
            };
          })
        };
      });
    }
    if (consentDescription) {
      consent['consentNickname'] = consentDescription;
    }
    return this.http.put(`${url}/customer-auth/consent`, consent, {headers});
  }

  putPaymentConsent(pickedAccount?: any) {
    const url = environment.supportingAPIUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    const consent = this.consentDetails;
    if (pickedAccount) {
      consent.debtorAccount = {
        schemeName: pickedAccount.schemeName,
        identification: pickedAccount.identification,
        name: pickedAccount.name
      };
    }
    return this.http.put(`${url}/customer-auth/consent`, consent, {headers});
  }

  putConsentCustomerDetails(customerDetails: any){
    const url = environment.supportingAPIUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`,
      'requestType': 'CUSTOMER_DETAILS'
    });
    const body = JSON.stringify(customerDetails);

    return this.http.put(`${url}/customer-auth/consent`, body, {headers});
  }

  confirmConsent() {
    const url = environment.supportingAPIUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });

    const body = {
      'authorisationStatus': true
    };

    return this.http.post(`${url}/customer-auth/confirmation`, body, {headers, observe: 'response'});
  }

  rejectConsent() {
    const url = environment.supportingAPIUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    const body = {
      'authorisationStatus': false
    };
    return this.http.post(`${url}/customer-auth/confirmation`, body, {headers, observe: 'response'});
  }

  

}
