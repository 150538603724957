import {Injectable} from "@angular/core";
import {CanonicalConsent, CanonicalDomesticRequest} from "./shared/interfaces";
import {JwtService} from "./shared/jwt.service";
import {AppTimeServices} from "./shared/appTime.service";
import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})

export class InitializerService {

  flowName: string;
  brandName: string;
  consentDetails: CanonicalConsent & CanonicalDomesticRequest;

  constructor(private jwtService: JwtService, private http: HttpClient, private appTime: AppTimeServices) {
  }

  getFlowName() {
    const flowName = `${this.jwtService.decodedJwt.standard}_${this.jwtService.decodedJwt.consentType}`;
    return flowName.toLowerCase();
  }

  setEnvironmentVariables(brandName: string) {
    if (environment.production || environment.qaOne || environment.qaThree) {
      environment.supportingAPIUrl = environment.api[brandName];
    } 
  }

  initializeApp() {
    this.jwtService.getJwt();
    this.appTime.startLocalTime(this.jwtService.decodedJwt.redirectUri);
    this.appTime.finalTime(this.jwtService.decodedJwt.exp, this.jwtService.decodedJwt.redirectUri);
    return new Promise((resolve, reject) => {
      if (this.jwtService.decodedJwt) {
        this.flowName = this.getFlowName();
        this.brandName = this.jwtService.decodedJwt.brand.toLowerCase();
        this.setEnvironmentVariables(this.brandName);
          resolve();
        
      } else {
        this.flowName = 'incorrect_jwt';
        resolve();
      }
    });
  }

}
