import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalComponent} from '../../../../OBIE/shared/common/modal/modal.component';
import {ModalService} from './modal.service';
import {LightboxModule} from '../../../../../shared/lightbox/lightbox.module';
import {ButtonModule} from '../../../../../shared/button/button.module';
import {LoaderModule} from '../../../../../shared/loader/loader.module';

@NgModule({
  declarations: [ModalComponent],
  exports: [ModalComponent],
  imports: [
    CommonModule,
    LightboxModule,
    ButtonModule,
    LoaderModule
  ],
  providers: [ModalService]
})
export class ModalModule {
}
