import {Inject} from "@angular/core";
import {InjectionToken} from "@angular/core";
import {DOCUMENT} from "@angular/common";

export interface FaviconsConfig {
  imageType: string;
  assetPath: string;
  iconSuffix: string;
  cacheBusting?: boolean;
}
export const FAVICONS_CONFIG = new InjectionToken<FaviconsConfig>("Favicons Configuration");

export class BrowserFavicons {

  private elementId: string;
  private useCacheBusting: boolean;
  private imageType: string;
  private assetPath: string;
  private iconSuffix: string;

  constructor(@Inject(FAVICONS_CONFIG) config: FaviconsConfig,
              @Inject(DOCUMENT) private _document: HTMLDocument) {

    this.elementId = "appFavicon";
    this.imageType = config.imageType;
    this.assetPath = config.assetPath;
    this.iconSuffix = config.iconSuffix;
    this.useCacheBusting = (config.cacheBusting || false);
  }

  public init(brand: string): void {
    const href: string = this.assetPath + brand + this.iconSuffix;
    this.setNode(this.imageType, href);
  }

  private removeNode(): void {
    const linkElement = this._document.getElementById(this.elementId);
    linkElement && this._document.head.removeChild(linkElement);
  }

  private setNode(type: string, href: string): void {
    const augmentedHref = this.useCacheBusting ? this.cacheBustHref(href) : href;
    this.removeNode();
    this.addNode(type, augmentedHref);
  }

  private addNode(type: string, href: string): void {
    let linkElement = this._document.createElement("link");
    linkElement.setAttribute("id", this.elementId);
    linkElement.setAttribute("rel", "icon");
    linkElement.setAttribute("type", type);
    linkElement.setAttribute("href", href);
    this._document.head.appendChild(linkElement);
  }

  private cacheBustHref(href: string): string {
    return (href.indexOf("?") === -1) ? `${href}?faviconCacheBust=${Date.now()}` : `${href}&faviconCacheBust=${Date.now()}`;
  }

}
