import {Component, NgZone, OnInit} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Modal} from '../../../../../shared/enums';
import {ModalService} from './modal.service';
import {ObieConsentsService} from '../../obie-consents.service';
import {JwtService} from '../../../../../shared/jwt.service';
import {appendDialogInsideComponent, getFirstUriDelimiter, getTppNameForPopup} from '../../../../../shared/utils';
import {AppTimeServices} from '../../../../../shared/appTime.service';
import {Subject} from 'rxjs';
import { CustomerSessionService } from 'src/app/shared/customer-session.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss', '../../obie-flows.scss']
})
export class ModalComponent implements OnInit {

  openedModals: any;
  showLoader: boolean;
  tppName: string;
  isDevice: boolean;
  brandId: string;
  refreshFlow: boolean;
  isStandingOrder: boolean;
  isPaymentInternational: boolean;
  sec: number;
  canStayLonger: boolean;
  additionalWording: string;
  isAmex:boolean;

  constructor(private modalService: ModalService, private consentService: ObieConsentsService, private deviceService: DeviceDetectorService, private jwtService: JwtService,
              private appTime: AppTimeServices, private ngZone: NgZone,  private customerSessionService: CustomerSessionService) {
    this.openedModals = {};
  }

  rejectConsentAndReturnToThirdParty() {
    this.showLoader = true;
    this.consentService.rejectConsent().subscribe(
      this.returnToThirdParty.bind(this),
      this.handleHttpError.bind(this)
    );
  }

  returnToThirdParty() {
    this.showLoader = false;
    const tppUri = this.jwtService.redirectUri;
    const redirectUri = `${tppUri}${getFirstUriDelimiter(tppUri)}error=access_denied&state=${this.jwtService.state}`;
    window.location.replace(redirectUri);
  }

  confirmConsent(dialogId: string) {
    this.closeModal(dialogId);
    this.modalService.confirmationEvent.next();
  }

  closeModal(dialogId: string) {
    document.body.classList.remove('overflow-y-hidden');
    appendDialogInsideComponent(dialogId);
    this.openedModals[dialogId] = false;
  }

  handleHttpError() {
    this.showLoader = false;
    this.modalService.openModal(Modal.SERVICE_UNAVAILABLE);
  }

  getPrimaryButtonClassName() {
    switch (this.jwtService.brand) {
      case 'hsbc':
        return 'red';
      case 'marksandspencer':
        return 'mns-primary';
      case 'firstdirect':
        return 'fd-primary';
      default:
        return 'red';
    }
  }

  getSecondaryButtonClassName() {
    switch (this.jwtService.brand) {
      case 'hsbc':
        return 'white';
      case 'marksandspencer':
        return 'mns-white';
      case 'firstdirect':
        return 'fd-secondary';
      default:
        return 'white';
    }
  }

  stayLonger(dialogId: string) {
    this.appTime.inactivityChecker();
    if (this.appTime.redirectCD) {
      this.appTime.redirectCD.unsubscribe();
    }
    this.closeModal(dialogId);
  }

  getTppNameForPopup() {
    return getTppNameForPopup(this.customerSessionService.tppName, this.customerSessionService.clientName);
  }
  
  ngOnInit() {
    this.modalService.action.subscribe((modalName: Modal) => {
      document.body.classList.add('overflow-y-hidden');
      this.openedModals[modalName] = true;

      if (modalName === Modal.NO_ELIGIBLE_ACCOUNTS_PISP) {
        this.modalService.additionalWording.subscribe((additionalWording: string) => {
          this.additionalWording = additionalWording;
        });
      }

    });
    this.appTime.timeLeftToRedirect.subscribe(response => {
      this.ngZone.run(() => {
        this.sec = response;
      });
    });
    this.appTime.canStayLonger.subscribe(response => {
      this.ngZone.run(() => {
        this.canStayLonger = response;
      });
    });
    this.canStayLonger = true;
    this.refreshFlow = this.jwtService.refreshFlow;
    this.tppName = this.jwtService.tppName;
    this.isDevice = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.isStandingOrder = this.jwtService.standingOrder;
    this.isPaymentInternational = this.jwtService.isPaymentInternational;
    this.isAmex = this.jwtService.isAmex;
    switch (this.jwtService.brand) {
      case 'hsbc':
        this.brandId = 'rb';
        break;
      case 'marksandspencer':
        this.brandId = 'mns';
        break;
      case 'firstdirect':
        this.brandId = 'fd';
        break;
      default:
        this.brandId = 'rb';
    }
  }

}
