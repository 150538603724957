import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-consent-fetch-failure',
  templateUrl: './consent-fetch-failure.component.html',
  styleUrls: ['./consent-fetch-failure.component.scss']
})
export class ConsentFetchFailureComponent implements OnInit {

  constructor() {
  }

  redirect() {
    window.location.replace('https://hsbc.com');
  }

  ngOnInit() {
  }

}
