import {AppComponent} from "./app.component";
import {BrowserModule} from "@angular/platform-browser";
import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {NgModule, APP_INITIALIZER} from "@angular/core";
import {TextContentService} from "./shared/text-content.service";
import {InitializerService} from "./initializer.service";
import {Route, RouterModule} from "@angular/router";
import {SharedModule} from "./shared/shared/shared.module";
import {IncorrectJwtComponent} from "./shared/incorrect-jwt/incorrect-jwt.component";
import {ServiceUnavailableModule} from "./shared/service-unavailable/service-unavailable.module";
import {IncorrectJwtModule} from "./shared/incorrect-jwt/incorrect-jwt.module";
import {AppLoadingComponent} from "./shared/app-loading/app-loading.component";
import {AppLoadingModule} from "./shared/app-loading/app-loading.module";
import {ConsentFetchFailureComponent} from "./shared/consent-fetch-failure/consent-fetch-failure.component";
import {ConsentFetchFailureModule} from "./shared/consent-fetch-failure/consent-fetch-failure.module";
import {LightboxModule} from "./shared/lightbox/lightbox.module";
import {ButtonModule} from "./shared/button/button.module";
import {AppTimeServices} from "./shared/appTime.service";
import {FAVICONS_CONFIG, BrowserFavicons} from "./shared/favicon/favicons";
import {LoaderModule} from "./shared/loader/loader.module";
import {DeviceDetectorModule} from 'ngx-device-detector';
import {ModalModule} from './flows/OBIE/shared/common/modal/modal.module';
import {FraudHeaderInterceptor} from "./flows/OBIE/shared/fraudHeader-interceptor";

export function setupTranslateFactory(service: TextContentService) {
  return () => service.getTextContent();
}

export function setupInitializeFactory(service: InitializerService) {
  return () => service.initializeApp();
}

const routes: Route[] = [
  {
    path: '',
    component: AppLoadingComponent
  },
  {
    path: 'service_unavailable',
    component: ConsentFetchFailureComponent
  },
  {
    path: 'consent_fetch_failure',
    component: ConsentFetchFailureComponent
  },
  {
    path: 'berlin_aisp',
    loadChildren: './flows/berlin/greece/greece-aisp-account-selection/greece-aisp-account-selection.module#GreeceAispAccountSelectionModule'
  },
  {
    path: 'hsbc_obie_aisp',
    loadChildren: './flows/OBIE/rbwm/AISP/obie-aisp-account-selection/obie-aisp-account-selection.module#ObieAispAccountSelectionModule'
  },
  {
    path: 'hsbc_obie_pisp',
    loadChildren: './flows/OBIE/rbwm/PISP/obie-pisp-domestic-payments/obie-pisp-domestic-payments.module#ObiePispDomesticPaymentsModule'
  },
  {
    path: 'hsbc_obie_cbpii',
    loadChildren: './flows/OBIE/rbwm/funds-confirmation/funds-confirmation.module#FundsConfirmationModule'
  },
  {
    path: 'firstdirect_obie_aisp',
    loadChildren: './flows/OBIE/fd/AISP/obie-aisp-account-selection/obie-aisp-account-selection.module#ObieAispAccountSelectionModule'
  },
  {
    path: 'firstdirect_obie_pisp',
    loadChildren: './flows/OBIE/fd/PISP/obie-pisp-domestic-payments/obie-pisp-domestic-payments.module#ObiePispDomesticPaymentsModule'
  },
  {
    path: 'firstdirect_obie_cbpii',
    loadChildren: './flows/OBIE/fd/funds-confirmation/funds-confirmation.module#FundsConfirmationModule'
  },
  {
    path: 'marksandspencer_obie_aisp',
    loadChildren: './flows/OBIE/mns/AISP/obie-aisp-account-selection/obie-aisp-account-selection.module#ObieAispAccountSelectionModule'
  },
  {
    path: 'marksandspencer_obie_pisp',
    loadChildren: './flows/OBIE/mns/PISP/obie-pisp-domestic-payments/obie-pisp-domestic-payments.module#ObiePispDomesticPaymentsModule'
  },
  {
    path: 'marksandspencer_obie_cbpii',
    loadChildren: './flows/OBIE/mns/funds-confirmation/funds-confirmation.module#FundsConfirmationModule'
  },
  {
    path: 'incorrect_jwt',
    component: IncorrectJwtComponent
  },
  {
    path: '**',
    component: AppLoadingComponent
  }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    SharedModule.forRoot(),
    IncorrectJwtModule,
    ServiceUnavailableModule,
    AppLoadingModule,
    ConsentFetchFailureModule,
    LightboxModule,
    ButtonModule,
    LoaderModule,
    DeviceDetectorModule.forRoot(),
    ModalModule
  ],
  providers: [
    BrowserFavicons,
    TextContentService,
    InitializerService,
    AppTimeServices,
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: FraudHeaderInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TextContentService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupInitializeFactory,
      deps: [InitializerService],
      multi: true
    },
    {
      provide: FAVICONS_CONFIG,
      useValue: {
        imageType: "image/png",
        assetPath: "./assets/images/",
        iconSuffix: "-fav-icon.png",
        cacheBusting: true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
