import {Injectable} from '@angular/core';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {CannonicalConsentAISP, CanonicalConsent, CanonicalDomesticRequest} from '../../../shared/interfaces';
import {environment} from '../../../../environments/environment';
import {JwtService} from '../../../shared/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class BerlinConsentsService {

  consentDetails: CanonicalConsent & CanonicalDomesticRequest;

  constructor(private http: HttpClient, private jwtService: JwtService) {
  }

  getConsentDetails() {
    const url = environment.supportingAPIUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    return this.http.get(`${url}/customer-auth/consent`, {headers});
  }

  putConsent(pickedAccounts: any) {
    const url = environment.supportingAPIUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    const consent: CannonicalConsentAISP = {};
    consent.permissions = this.consentDetails.permissions.map(permission => {
      return {
        permission: permission.permission,
        accounts: pickedAccounts.map(pickedAccount => {
          return {
            schemeName: pickedAccount.schemeName,
            identification: pickedAccount.identification
          };
        })
      };
    });
    return this.http.put(`${url}/customer-auth/consent`, consent, {headers});
  }

  confirmConsent() {
    const url = environment.supportingAPIUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    const body = {
      'authorisationStatus': true
    };
    return this.http.post(`${url}/customer-auth/confirmation`, body, {headers, observe: 'response'});
  }

  rejectConsent() {
    const url = environment.supportingAPIUrl;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.jwtService.jwt}`
    });
    const body = {
      'authorisationStatus': false
    };
    return this.http.post(`${url}/customer-auth/confirmation`, body, {headers, observe: 'response'});
  }

}
