export const environment = {
  production: true,
  qaOne: false,
  qaTwo: false,
  qaThree: false,
  preprod: true,
  supportingAPIUrl: 'https://uat-ob.hangseng.com',
  browserRedirectUrl:'https://hase-aem-tko-lp.hsbc.com.hk/zh-hk/personal/banking/pay-with-hangseng/',
  api: {
    "general": "https://ju2twcz3d0.execute-api.eu-west-1.amazonaws.com/preprod",
    "hase":"https://uat-ob.hangseng.com",
    "firstdirect": "https://oz9u1bt30d.execute-api.eu-west-1.amazonaws.com/preprod",
    "marksandspencer": "https://j88ujb6pa1.execute-api.eu-west-1.amazonaws.com/preprod"
}
};
