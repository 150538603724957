import {Component, OnInit} from "@angular/core";
import {JwtService} from "../jwt.service";

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss']
})
export class ServiceUnavailableComponent implements OnInit {

  constructor(private jwtService: JwtService) {
  }

  redirect() {
    window.location.replace(this.jwtService.redirectUri);
  }

  ngOnInit() {
  }

}
