import {Component, Input, Output, EventEmitter} from "@angular/core";
import {JwtService} from "../jwt.service";
import { AppTimeServices } from "../appTime.service";

@Component({
  selector: 'btn',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() label: string;
  @Input() className: string;
  @Input() isDisabled: boolean;
  @Input() isLink: boolean;
  @Input() id: string;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor( private jwtService: JwtService, private appTime: AppTimeServices) {
  }

  onClickButton(item: any) {
    this.onClick.emit(item);
  }

}
